<script>
import benefits from './images/ic-beneficios.png'
import ingredients from './images/ic-ingredientes.png'
import usageTips from './images/ic-tips.png'
import bgProduct from './images/bg-product.jpg'

export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside', 'bottom'],
	showHook(hookZone, ProductPage) {
		return ProductPage.product.info.templateKey === 'customTabs'
	},
	data() {
		return {
			tab: null,

			bgProduct,
			images: {
				benefits,
				ingredients,
				usageTips,
			},
		}
	},
	created() {
		this.tab = this.items[0]
	},
	computed: {
		templateData() {
			return this.ProductPage.product.info.templateData
		},
		showDescriptionAndAttrs() {
			return (
				(this.$b.d && this.currentZone == 'after--images') ||
				(this.$b.mt && this.currentZone == 'bottom-inside')
			)
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
		items() {
			const keys = ['benefits', 'ingredients', 'usageTips']
			const types = {
				benefits: 'Beneficios',
				ingredients: 'Ingredientes',
				usageTips: 'Tips de Uso',
			}

			return Object.keys(this.templateData)
				.filter((k) => keys.includes(k) && this.templateData[k]?.length)
				.map((key) => {
					return {
						key,
						title: types[key],
						content: this.templateData[key],
						img: this.images[key],
					}
				})
		},
	},
}
</script>

<template>
	<div>
		<template v-if="showDescriptionAndAttrs">
			<v-divider />
			<ProductPage-Description :templateData="templateData" :product="ProductPage.product" class="pa-3" />
			<ProductPage-Attributes :attributes="attrs" class="pa-3" />
		</template>
		<div :class="$bem('bioesencia-tabs')" class="mt-4" v-if="currentZone == 'bottom'">
			<v-slide-group :class="$bem('__slide-group')" class="ml-md-9" center-active show-arrows mandatory>
				<v-slide-item
					:class="$bem('__slide-item')"
					v-slot="{ active, toggle }"
					v-for="item in items"
					:key="item.key"
					class="font-2 font-weight-bold pointer px-7 rounded-t pb-5 pt-3"
					style="text-transform: none"
				>
					<div
						:class="$bem(active ? '__slide-content-active' : '__slide-content')"
						@click="
							toggle()
							tab = item
						"
						:style="{
							background: active ? 'var(--secondary)' : '',
							color: active ? 'var(--secondarytext)' : '',
						}"
					>
						<div :class="$bem('__image-container')" class="d-flex justify-center">
							<Media
								:class="$bem('__image')"
								width="69px"
								height="92px"
								:src="item.img"
								as="img"
								class="mx-4 mb-2"
							/>
						</div>
						<h3 :class="$bem('__title')" class="font-4 text-uppercase">
							{{ item.title }}
						</h3>
					</div>
				</v-slide-item>
			</v-slide-group>
			<div
				:class="$bem('__main-content')"
				class="secondary px-6 py-8"
				:style="{ backgroundImage: 'url(' + bgProduct + ')', whiteSpace: 'pre-line' }"
				v-html="tab.content"
			></div>
		</div>
	</div>
</template>

<style scoped lang="scss">
::v-deep .v-slide-group__wrapper {
	contain: none;
}
.bioesencia-tabs {
	&__main-content {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top left;
		border-radius: 10px;
	}
}
</style>

