var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showDescriptionAndAttrs)?[_c('v-divider'),_c('ProductPage-Description',{staticClass:"pa-3",attrs:{"templateData":_vm.templateData,"product":_vm.ProductPage.product}}),_c('ProductPage-Attributes',{staticClass:"pa-3",attrs:{"attributes":_vm.attrs}})]:_vm._e(),(_vm.currentZone == 'bottom')?_c('div',{staticClass:"mt-4",class:_vm.$bem('bioesencia-tabs')},[_c('v-slide-group',{staticClass:"ml-md-9",class:_vm.$bem('__slide-group'),attrs:{"center-active":"","show-arrows":"","mandatory":""}},_vm._l((_vm.items),function(item){return _c('v-slide-item',{key:item.key,staticClass:"font-2 font-weight-bold pointer px-7 rounded-t pb-5 pt-3",class:_vm.$bem('__slide-item'),staticStyle:{"text-transform":"none"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{class:_vm.$bem(active ? '__slide-content-active' : '__slide-content'),style:({
						background: active ? 'var(--secondary)' : '',
						color: active ? 'var(--secondarytext)' : '',
					}),on:{"click":function($event){toggle()
						_vm.tab = item}}},[_c('div',{staticClass:"d-flex justify-center",class:_vm.$bem('__image-container')},[_c('Media',{staticClass:"mx-4 mb-2",class:_vm.$bem('__image'),attrs:{"width":"69px","height":"92px","src":item.img,"as":"img"}})],1),_c('h3',{staticClass:"font-4 text-uppercase",class:_vm.$bem('__title')},[_vm._v(" "+_vm._s(item.title)+" ")])])]}}],null,true)})}),1),_c('div',{staticClass:"secondary px-6 py-8",class:_vm.$bem('__main-content'),style:({ backgroundImage: 'url(' + _vm.bgProduct + ')', whiteSpace: 'pre-line' }),domProps:{"innerHTML":_vm._s(_vm.tab.content)}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }