import { render, staticRenderFns } from "./comp_Hook-ProductPage-CustomTabs.vue?vue&type=template&id=3651a54e&scoped=true"
import script from "./comp_Hook-ProductPage-CustomTabs.vue?vue&type=script&lang=js"
export * from "./comp_Hook-ProductPage-CustomTabs.vue?vue&type=script&lang=js"
import style0 from "./comp_Hook-ProductPage-CustomTabs.vue?vue&type=style&index=0&id=3651a54e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3651a54e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VDivider,VSlideGroup,VSlideItem})
